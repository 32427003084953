import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { chartsHeight as height } from 'helpers/styleHelpers';
import EMEABreadcrumb from 'components/EMEA/EMEA_Common/EMEABreadcrumb';
import EMEAChartCard from 'components/EMEA/EMEA_Common/EMEAChartCard';
import EmeaCustomTable from 'components/EMEA/EMEA_Common/EmeaCustomTable';
import { useStyles } from 'components/EMEA/EMEAInvoiceStatus';
import { emeaInvoiceSummaryDetailsColumns } from "reduxLib/constdata/EMEA/emeaInvoiceSummaryDetailsColumns";

const EMEAInvoiceSummaryInvoicesDetails = () => {

  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const classes = useStyles();
  const classes1 = useStyles({ height });
  const theme = useTheme();

  const isMobile = useMediaQuery(useTheme().breakpoints.down('md'));

  const [chartWidth] = useState(() => {
    return window.innerWidth - window.innerWidth / 10 * 1
  });
  // const [refresh, setRefresh] = useState(false);

  const additionalFilters = {
    orderType: "ORDER_ENTRY"
  }

  const handleLinkClick = () => {
    let updatedPath = location.pathname;
    updatedPath = updatedPath.replace("/invoiceDetails", "");
    history.push({
      ...location,
      pathname: updatedPath
    })
  }

  const chartInvoiceDetails = {
    subtype: "SUBTYPE_EMEA_INVOICES_DETAILS_INVOICED_OPEN_GAP",
    colorPalette: "set10",
    legend: false,
    colorBy: "yKey",
    customTooltipKey: "state",
    xKey: "stateDescCustom",
    showMonthFilter: false,
    axisSuffix: "%",
    barSuffix: "%",    
    maxWidth: chartWidth,
    minWidth: chartWidth,
    height: 500,
    legendGroup: true, 
    monthFilter: true,
    keys: ["invoicePer", "openPer", "gapPer"],
    keyLabelMaps: {
      invoicePer: t('invoiced'),
      openPer: t('not_invoiced'),
      gapPer: t('gap'),
    },
    chart: "trendline",
    decimals: 2,
    // refresh,
    // setRefresh,
    showEllipsis: isMobile,
    testId: "invoice-details-open-gap-chart",
    refreshIcon: false,
    displayKey: "stateDesc",
    tooltipKey: "state",
    legendGroupItems: [
      { type: 'square', color: theme.palette.set10[0], label: t('invoiced'), key: "invoicePer" },
      { type: 'square', color: theme.palette.set10[1], label: t('not_invoiced'), key: "openPer" },
      { type: 'square', color: theme.palette.set10[2], label: t('gap'), key: "gapPer" },
    ],
  }

  return (
    <>
      <EMEABreadcrumb pageName={'invoices_details'} />
      <div className={classes.box}>
        <Grid container justifyContent="flex-end" >
          <Button className={classes.buttonLink} disableRipple data-testid="page-link-btn" size='small' onClick={handleLinkClick} >
            {t("goto_invoices")}
          </Button>
        </Grid>
        <Card className={[classes1.mt2, classes1.root]} data-testid="invoiceSummaryInvoices">
          <CardContent p={0}>
            <Grid
              container
              spacing={3}
              alignItems="center"
              justifyContent="center"
            >
              <Grid item p={1}>
                <EMEAChartCard
                  {...chartInvoiceDetails}
                  excludedFilters={additionalFilters}
                  boxShadow="none"
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </div>
      <EmeaCustomTable subType={"SUBTYPE_EMEA_INVOICES_DETAILS_INVOICED_OPEN_GAP_TABULAR"} columnHeaders={emeaInvoiceSummaryDetailsColumns} />
    </>
  )
}

export default EMEAInvoiceSummaryInvoicesDetails